import React from 'react'
import {Link} from 'gatsby'

const ServiceSidebar = () => {
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <Link to="/service-details" className="active">
                        Home Mac Support
                    </Link>
                </li>
                <li>
                    <Link to="/service-details">
                        Business Mac Support
                    </Link>
                </li>
                <li>
                    <Link to="/service-details">
                        Business Mac Friendly Solutions
                    </Link>
                </li>
                <li>
                    <Link to="/service-details">
                        Amazon AWS Support
                    </Link>
                </li>
                <li>
                    <Link to="/service-details">
                        Website Design and Hosting
                    </Link>
                </li>
            </ul>

            <div className="download-file">
                <h3>Brochures</h3>

                <ul>
                    <li>
                        <Link to="#">
                            PDF Download <i className='bx bxs-file-pdf'></i>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            Services Details.txt <i className='bx bxs-file-txt'></i>
                        </Link>
                    </li>
                </ul>
            </div>

            <div className="services-contact-info">
                <h3>Contact Info</h3>
                
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Phone:</span>
                        <a href="tel:01273 092888">01273 092888</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
                        24 Turnpike, Ringmer, East Sussex
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-envelope'></i>
                        </div>
                        <span>Email:</span>
                        <a href="mailto:support@spurdotech.co.uk">support@spurdotech.co.uk</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ServiceSidebar