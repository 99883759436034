import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
//import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Mac & Windows Support for Home & Business</span>
                            <h3>Warranty Expired? No Worries.</h3>
                                <p>Whether it's an Apple or Windows device, once your standard warranty concludes, your choices shift.</p> 
                                <p>But our dedication doesn't. We’re here to ensure you continue to enjoy unparalleled service.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Our Pledge to You</h3>
                                        <ul>
                                            <li>Customer-Centric: We value and honour every client we serve.</li>
                                            <li>Endless Patience: Every user, every time.</li>
                                            <li>Flexibility: Adapting to each unique tech challenge.</li>
                                            <li>Surpass Expectations: We aim not just to serve but to impress.</li>
                                            <li>Amicability & Expertise: Always approachable, always enlightening.</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>One Rate, All Clients
                            Be it at your cosy home or bustling business, we offer uniform Mac and Windows support.</p> 
                            <p>Broad Industry Proficiency
                            From the arts to manufacturing, solo entrepreneurs to large families, we've got your Mac, Windows, and IT essentials handled.</p>

                            <h3>We cover Mac & IT across many industries:</h3>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Self Employed
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Arts
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Nans and Aunties
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Home
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Music
                                    </div>
                                </div>
                            </div>

                            <h3>Tech Expertise We're Proud Of</h3>
                            <ul className="technologies-features">
                                <li><span>MacOS, IOS & Windows platforms and software</span></li>
                                <li><span>Comprehensive cloud solutions, including Amazon AWS</span></li>
                                <li><span>Mirosoft O365 support</span></li>
                                <li><span>Data Backup & Restoration</span></li>
                                <li><span>All Wi-Fi inc Cisco, Ubiquity</span></li>
                                <li><span>All Mac Software Programs</span></li>
                                <li><span>Troubleshooting everything, from software glitches to hardware hiccups.</span></li>

                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent